<template>
  <section class="tw-flex-1 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-min-h-40 md:tw-min-h-96">
    <section v-if="isPending" class="tw-flex-1 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
      <!-- <slot v-if="$slots.loading" name="loading"></slot>
      <span v-else class="tw-loading tw-loading-infinity tw-loading-lg tw-relative -tw-top-10"></span> -->
      <div
        class="tw-w-full tw-h-full"
        :class="{
          'tw-max-w-80 tw-max-h-80': size === 'large',
          'tw-max-w-40 tw-max-h-40 tw-relative -tw-top-10': size === 'medium',
        }"
        ref="loadingRef"
      />
    </section>
    <section v-else-if="error" class="tw-flex-1 tw-w-full tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-4">
      <SvgoCommonToastWarn class="tw-h-10 tw-w-10 tw-fill-error tw-relative tw-top-1" />
      <div class=" tw-flex tw-items-baseline tw-gap-1">
        <p class="tw-text-error tw-flex tw-flex-col tw-items-baseline tw-justify-center tw-gap-2 tw-text-sm lg:tw-text-base tw-text-center">{{ error.message }} </p>
      </div>
      <a class="tw-btn tw-btn-xs tw-btn-link" @click="reload">{{ $t('kyc.Refresh') }}</a>
    </section>
    <section class="tw-flex-1 tw-flex tw-flex-col tw-w-full tw-h-full tw-relative" v-else-if="isSuccess">
      <div
        class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex-none tw-flex tw-justify-center tw-items-center tw-bg-white/20 tw-z-50"
        v-if="isFetching"
      >
        <!-- <slot v-if="$slots.loading" name="loading"></slot>
        <span v-else class="tw-loading tw-loading-dots tw-loading-lg"></span> -->
        <div
          class="tw-w-full tw-h-full"
          ref="loadingRef"
          :class="{
            'tw-max-w-80 tw-max-h-80': size === 'large',
            'tw-max-w-40 tw-max-h-40': size === 'medium',
          }"
        />
      </div>
      <slot />
    </section>
  </section>
</template>
<script setup lang="ts">
  const props = withDefaults(
    defineProps<{ isPending: boolean; error: any; isSuccess: boolean; isFetching?: boolean; size?: 'large' | 'medium' }>(),
    {
      size: 'medium',
    },
  )
  const animation = ref()
  const loadingRef = ref()
  onMounted(async () => {
    const [lottie, AnimationData] = await Promise.all([import('lottie-web'), import('@/assets/animation/loading.json')])
    const ele = lottie.default.loadAnimation({
      container: loadingRef.value as HTMLDivElement,
      renderer: 'svg',
      loop: true,
      animationData: AnimationData.default,
    })
    animation.value = ele
  })

  onUnmounted(() => {
    animation.value?.destroy?.()
    animation.value = {}
  })
  const router = useRouter()
  const route = useRoute()
  watchEffect(() => {
    if (props.error) {
      if (props.error.code === '303102') {
        router.push({
          ...route,
          query: {
            ...route.query,
            state: 303102,
          },
        })
      }
    }
  })

  const reload=()=>{
    window.location.reload()
  }
</script>
