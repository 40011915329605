<template>
  <UIQuery :isPending="loading" :isSuccess="isSuccess" :isFetching="isFetching" :error="error" size="large" class="!tw-items-start">
    <KycIndividualList v-if="state === 10" />
    <KycInstitutionList v-else-if="state === 11" />
    <KycIndividualPage v-else-if="state === 20" />
    <KycInstitutionPage v-else-if="state === 21" />
    <KycApplyDone v-else-if="state === 100 || state === 101" />
    <KycApplyFailed v-else />
  </UIQuery>
</template>
<script setup lang="ts">
  const { getSign, isSuccess, isPending, isFetching, error } = useSign()
  const route = useRoute()
  const state = computed(() => parseInt((route.query.state as string) ?? '0'))
  const loading=ref<boolean>(false)

  onMounted(async () => {
    loading.value=true
    await getSign()
    loading.value=false
  })

</script>
