/**
 * 查看个人信息
 * @returns
 */
export const useIndividualPersonalQuery = () => {
  return useQuery({
    queryKey: ['useIndividualPersonalInfoQuery'],
    queryFn: () =>
      fetchByGet<[IIndividualPersonalInfo]>('/v2/kyc/individual', {
        'query[]': [
          'account_email',
          'telegram',
          'first_name',
          'middle_name',
          'last_name',
          'aliases',
          'birthdate',
          'place_of_birth',
          'citizenship',
          'phone',
          'country_iso2_code',
          'city',
          'state',
          'zipcode',
          'address',
          'address_line_2',
          'ssn',
          'proof_of_residence_file',
        ],
      }),
  })
}

/**
 * 保存个人信息
 * @returns
 */
export const useIndividualPersonalMutation = () => {
  return useMutation({
    mutationFn: (params: IIndividualPersonalInfo) => {
      const $params = {
        ...params,
        ssn: params.country_iso2_code == 'US' ? params.ssn : undefined,
        proof_of_residence_file: params.proof_of_residence_file.map((m) => m.file_hash),
      }
      return fetchByPost('/v2/kyc/individual/save', $params)
    },
  })
}

/**
 * 查看个人银行信息
 * @returns
 */
export const useIndividualBankQuery = () => {
  return useQuery({
    queryKey: ['useIndividualBankQuery'],
    queryFn: () =>
      fetchByGet<
        [
          {
            bank_country: string
            // answers: { bank_file: Array<IFile> }
          },
        ]
      >('/v2/kyc/individual/bank'),
  })
}

/**
 * 提交个人银行信息
 * @returns
 */
export const useIndividualBankMutation = () => {
  return useMutation({
    mutationFn: (params: { bank_country: string }) => fetchByPost('/v2/kyc/individual/bank', params),
  })
}

/**
 * 获取 个人问题
 * @returns
 */
export const useIndividualQAQuery = () => {
  return useQuery({
    queryKey: ['useIndividualQAQuery'],
    queryFn: () => fetchByGet<IQuestion[][]>('/v2/kyc/individual/qa', {}),
  })
}

/**
 * 提交个人问题
 * @returns
 */
export const useIndividualQAMutation = () => {
  return useMutation({
    mutationFn: (params: Array<Record<string, string>>) => fetchByPost('/v2/kyc/individual/qa', params),
  })
}

/**
 * 获取 veriff 链接
 * @returns
 */
export const useIndividualSessionQuery = () => {
  return useQuery({
    queryKey: ['useIndividualSessionQuery'],
    queryFn: () => fetchByGet<[{ session_url: string }]>('/v2/kyc/individual/idv/session', {}),
    // enabled: false,
  })
}

/**
 * 个人 KYC 提交
 * @returns
 */
export const useIndividualSubmitMutation = () => {
  return useMutation({
    mutationFn: () => fetchByPost('/v2/kyc/individual/submit', {}),
  })
}

/**
 * 企业 KYC 提交
 * @returns
 */
export const useInstitutionSubmitMutation = () => {
  return useMutation({
    mutationFn: () => fetchByPost('/v2/kyc/institution/submit', {}),
  })
}

/**
 * 查看企业信息
 * @returns
 */
export const useInstitutionGeneralQuery = () => {
  return useQuery({
    queryKey: ['useInstitutionGeneralQuery'],
    queryFn: () => fetchByGet<IInstitutionGeneral[]>('/v2/kyc/institution/general', {}),
    select: (result) => {
      const { data } = result
      const _data =
        data && data.length
          ? data.map((item) => {
              return {
                ...item,
                entity_type: item.entity_type?.[0],
              }
            })
          : data
      return { ...result, data: _data }
    },
  })
}

/**
 * 保存企业信息
 * @returns
 */
export const useInstitutionGeneralMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: Partial<IInstitutionGeneral>) => {
      const $params = {
        ...params,
      }
      // 必须分开写
      if (params.entity_type !== undefined) {
        $params.entity_type = (typeof params.entity_type === 'string' ? [params.entity_type] : []) as any
      }
      if (params.tax_identification_number !== undefined) {
        $params.tax_identification_number = params.country_of_formation == 'US' ? params.tax_identification_number : ''
      }
      return fetchByPost('/v2/kyc/institution/general/save', $params)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['useInstitutionGeneralQuery'] })
    },
  })
}

/**
 * 查看文档信息
 * @returns
 */
export const useInstitutionDocumentsQuery = () => {
  return useQuery({
    queryKey: ['useInstitutionDocumentsQuery'],
    queryFn: () => fetchByGet<IInstitutionDocuments[]>('/v2/kyc/institution/documents', {}),
  })
}

/**
 * 保存文档信息
 * @returns
 */
export const useInstitutionDocumentsMutation = () => {
  return useMutation({
    mutationFn: (params: IInstitutionDocuments) => {
      const $params = {
        ...params,
        policy: params.regulated_financial_institution === 'Yes' ? params.policy : '',
        institution_doc_policy: params.regulated_financial_institution === 'Yes' ? params.institution_doc_policy : [],
      }
      return fetchByPost('/v2/kyc/institution/documents/save', $params)
    },
  })
}

/**
 * 文档模块文件上传
 * @returns
 */
export const useInstitutionDocumentsFileMutation = () => {
  return useMutation({
    mutationFn: (params: FormData) => fetchByFile<[IFile]>('/v2/kyc/institution/documents/upload', params),
  })
}

/**
 * 查看企业银行信息
 * @returns
 */
export const useInstitutionBankQuery = () => {
  return useQuery({
    queryKey: ['useInstitutionBankQuery'],
    queryFn: () =>
      fetchByGet<
        [
          {
            bank_country: string
            bank_file: Array<IFile>
            answers: { bank_file: Array<IFile> }
          },
        ]
      >('/v2/kyc/institution/banking'),
  })
}

/**
 * 保存企业银行信息
 * @returns
 */
export const useInstitutionBankMutation = () => {
  return useMutation({
    mutationFn: (params: { bank_country: string }) => fetchByPost('/v2/kyc/institution/banking', params),
  })
}

/**
 * 获取企业问题
 * @returns
 */
export const useInstitutionQAQuery = () => {
  return useQuery({
    queryKey: ['useInstitutionQAQuery'],
    queryFn: () => fetchByGet<IQuestion[][]>('/v2/kyc/institution/qa', {}),
  })
}

/**
 * 保存企业问题答案
 */
export const useInstitutionQAMutation = () => {
  return useMutation({
    mutationFn: (params: Array<Record<string, string>>) => fetchByPost('/v2/kyc/institution/qa/save', params),
  })
}

export const useInstitutionRepresentativeListQuery = () => {
  return useQuery({
    queryKey: ['useInstitutionRepresentativeListQuery'],
    queryFn: async () => {
      const [data1, data2] = await Promise.all([
        fetchByGet<{ is_only_owner: boolean }[]>('/v2/kyc/institution/internal_stakeholders/is_only_owner', {}),
        fetchByGet<IInstitutionRepresentativeOrOwner[]>('/v2/kyc/institution/internal_stakeholders/representative/list', {}),
      ])
      return {
        code: data1.code,
        message: data1.message,
        success: data1.success,
        data: {
          is_only_owner: data1.data[0].is_only_owner as boolean,
          list: (data2.data ?? []) as unknown as IInstitutionRepresentativeOrOwner[],
        },
      }
    },
  })
}

export const useInstitutionRepresentativeQuery = (id: string, defaultValue: IInstitutionRepresentativeOrOwner) => {
  return useQuery({
    queryKey: ['useInstitutionRepresentativeQuery', id],
    queryFn: () => fetchByGet<IInstitutionRepresentativeOrOwner[]>(`/v2/kyc/institution/internal_stakeholders/representative/${id}`, {}),
    enabled: !!id,
    initialData: () => ({ data: [defaultValue] }),
  })
}

export const useInstitutionRepresentativeMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: Partial<IInstitutionRepresentativeOrOwner>) => {
      const { id, ...other } = params
      if (id) return fetchByPut(`/v2/kyc/institution/internal_stakeholders/representative/${id}`, other)
      else return fetchByPost('/v2/kyc/institution/internal_stakeholders/representative', params)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['useInstitutionRepresentativeListQuery'] })
    },
  })
}

export const useDelInstitutionRepresentativeMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => {
      return fetchByDelete(`/v2/kyc/institution/internal_stakeholders/representative/${id}`)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['useInstitutionRepresentativeListQuery'] })
    },
  })
}

export const useDelAllInstitutionRepresentativeMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => {
      return fetchByPatch(`/v2/kyc/institution/internal_stakeholders/change_type`)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['useInstitutionRepresentativeListQuery'] })
    },
  })
}

/**
 * 获取 InstitutionOwner 列表
 * @returns
 */
export const useInstitutionOwnerListQuery = () => {
  return useQuery({
    queryKey: ['useInstitutionOwnerListQuery'],
    queryFn: () => fetchByGet<IInstitutionRepresentativeOrOwner[]>('/v2/kyc/institution/internal_stakeholders/owner/list', {}),
  })
}

/**
 * 根据 id 获取 InstitutionOwner 信息
 * @param id
 * @param defaultValue
 * @returns
 */
export const useInstitutionOwnerQuery = (id: string, defaultValue: IInstitutionRepresentativeOrOwner) => {
  return useQuery({
    queryKey: ['useInstitutionOwnerQuery', id],
    queryFn: () => fetchByGet<IInstitutionRepresentativeOrOwner[]>(`/v2/kyc/institution/internal_stakeholders/owner/${id}`, {}),
    enabled: !!id,
    initialData: () => ({ data: [defaultValue] }),
  })
}

/**
 * 保存 InstitutionOwner
 * @returns
 */
export const useInstitutionOwnerMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: Partial<IInstitutionRepresentativeOrOwner>) => {
      const { id, ...other } = params
      if (id) return fetchByPut(`/v2/kyc/institution/internal_stakeholders/owner/${id}`, other)
      else return fetchByPost('/v2/kyc/institution/internal_stakeholders/owner', params)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['useInstitutionOwnerListQuery'] })
    },
  })
}

/**
 * 删除 InstitutionOwner
 * @returns
 */
export const useDelInstitutionOwnerMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: number) => {
      return fetchByDelete(`/v2/kyc/institution/internal_stakeholders/owner/${id}`)
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['useInstitutionOwnerListQuery'] })
    },
  })
}

/**
 * 查看 Parent Company 列表
 * @returns
 */
export const useInstitutionParentsQuery = () => {
  return useQuery({
    queryKey: ['useInstitutionParentsQuery'],
    queryFn: () => fetchByGet<IInstitutionParent[]>('/v2/kyc/institution/parent', {}),
    select: (result) => {
      const { data } = result
      const _data =
        data && data.length
          ? data.map((item) => {
              return {
                ...item,
                entity_type: item.entity_type?.[0],
              }
            })
          : data
      return { ...result, data: _data }
    },
  })
}

/**
 * 根据 id 查看 Parent Company
 * @param id
 * @param defaultValue
 * @returns
 */
export const useInstitutionParentInfoQuery = (id: string, defaultValue: IInstitutionParent) => {
  return useQuery({
    queryKey: ['useInstitutionParentInfoQuery'],
    queryFn: () => fetchByGet<IInstitutionParent[]>('/v2/kyc/institution/parent/info', { id }),
    enabled: !!id,
    initialData: () => ({ data: [defaultValue] }),
    select: (result) => {
      const { data } = result
      const _data =
        data && data.length
          ? data.map((item) => {
              return {
                ...item,
                entity_type: item.entity_type?.[0],
              }
            })
          : data
      return { ...result, data: _data }
    },
  })
}

/**
 * 保存 Parent Company
 * @returns
 */
export const useInstitutionParentMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: Partial<IInstitutionParent>) => {
      return fetchByPost(
        '/v2/kyc/institution/parent/save',
        params.parent_company === 'Yes' ? { ...params, entity_type: [params.entity_type] } : { parent_company: 'No' },
      )
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['useInstitutionParentsQuery'] })
    },
  })
}

/**
 * 删除 Parent Company
 * @returns
 */
export const useDeleteInstitutionParentMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => {
      return fetchByPost('/v2/kyc/institution/parent/save/delete', { id })
    },
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['useInstitutionParentsQuery'] })
    },
  })
}
