import { toast } from 'vue3-toastify'

export const logSuccess = (res: any) => {
  /* eslint-disable-next-line */
  console.log('%c Request Success: %s', 'color: #4CAF50; font-weight: bold', res.config.url, res)
  return res
}

export const logError = (err: any) => {
  /* eslint-disable-next-line */
  console.log('%c Request Error:', 'color: #EC6060; font-weight: bold', err)
  return Promise.reject(err)
}

export const displayErrors = (err: any) => {
  if (err.response) {
    const { message, errors } = err.response.data
    if (typeof errors === 'object') {
      Object.entries(errors).forEach(([key, msgs]) => {
        let description: any = ''
        if (Array.isArray(msgs)) {
          description = msgs.join('\n')
        } else {
          description = msgs
        }
        toast.warning(description)
      })
    } else {
      toast.error(message)
    }
  } else {
    toast.error(err.message)
  }
}

export const scrollToTwBorderError = () => {
  document.getElementsByClassName('tw-border-error')?.[0]?.scrollIntoView(true)
}
