<template>
  <KycCommonHeader
    :title="$t('kyc.onboarding')"
    :subtitle="accountType === 'Individual' ? 'Individual' : accountType === 'Entity' ? $t('kyc.institution') : ''"
    @go-back="$router.back()"
  />
  <section class="tw-text-center">
    <div class="tw-flex tw-flex-col tw-gap-14 tw-py-14">
      <!-- <p class="tw-text-base md:tw-text-lg tw-text-center" v-if="accountEmail">{{ accountEmail }}</p> -->

      <div class="tw-w-20 tw-h-20 tw-mx-auto tw-fill-[#48BC19]">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>check-circle-outline</title>
          <path
            d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"
          />
        </svg>
      </div>

      <div class="tw-flex tw-flex-col tw-gap-3 tw-justify-center">
        <h3 class="tw-text-2xl md:tw-text-3xl !tw-font-black">
          {{ $t('kyc.done_title', { type: isBroker ? '合伙人' : 'OTC' }) }}
        </h3>
        <p class="tw-text-base md:tw-text-xl tw-text-center tw-font-bold tw-text-neutral" v-if="accountEmail">{{ accountEmail }}</p>
        <!-- <div>
          <p class="tw-text-sm !tw-leading-normal" v-for="(item, index) in $tm('done_subtitle')" :key="index">
            <template v-if="$rt(item).includes('{email}')">
              <a :href="`mailto:${customerSupport}`">
                {{ customerSupport }}
              </a>
            </template>
            <template v-else>
              {{ $rt(item) }}
            </template>
          </p>
        </div> -->
      </div>
      <div
        class="tw-card tw-max-w-[800px] tw-mx-auto tw-bg-[#FAFAFB] tw-px-5 md:tw-px-10 lg:tw-px-20 tw-py-7 md:tw-py-7 lg:tw-py-16 tw-rounded-2xl md:tw-rounded-3xl tw-text-left tw-flex tw-flex-col tw-gap-5"
      >
        <p>{{ $t('kyc.done_message[0]') }}</p>

        <i18n-t keypath="kyc.done_message[1]" tag="p">
          <span class="tw-font-black">{{ $t('done_junk') }}</span>
        </i18n-t>

        <i18n-t keypath="kyc.done_message[2]" tag="p">
          <a class="tw-text-primary" :href="`mailto:${customerSupport}`">
            {{ customerSupport }}
          </a>
        </i18n-t>
      </div>
      <div class="tw-flex tw-justify-center">
        <UIButton @click="submit" class="tw-btn-lg md:tw-btn-xl xl:tw-btn-2xl">
          {{ $t('kyc.submit_a_new_application') }}
        </UIButton>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
  const route = useRoute()
  const router = useRouter()
  const { getEmail } = useSession()
  const accountEmail = getEmail()
  const accountType = route.params.state === '100' ? 'Individual' : 'Entity'
  const { common } = useApi()
  const { data } = common.useAccountCustomSupportQuery()
  const isBroker = false
  const customerSupport = computed(() => (unref(data) as any)?.cs)
  const submit = () => {
    router.push({
      ...route,
      query: {
        ...route.query,
        state: 0,
      },
    })
  }
</script>
