<template>
  <KycCommonHeader :title="$t('kyc.onboarding')" @go-back="$router.back()" />
  <section class="tw-text-center">
    <div class="tw-flex tw-flex-col tw-gap-12 tw-py-14">
      <div class="tw-flex tw-flex-col tw-gap-3 tw-justify-center">
        <h3 class="tw-text-2xl md:tw-text-3xl tw-text-[#0a0a16]"> {{ $t('kyc.fail_title') }} </h3>
      </div>
      <div class="tw-mx-auto tw-fill-[#facc28]">
        <svg width="14" height="55" viewBox="0 0 14 55" xmlns="http://www.w3.org/2000/svg">
          <g fill="#FACC28" fill-rule="evenodd">
            <path d="M6.804 43C2.432 23.048.18 12.024.047 9.926c-1.017-13.235 14.671-13.235 13.927 0-.055 1.76-2.445 12.785-7.17 33.074z" />
            <circle cx="7.5" cy="51.5" r="3.5" />
          </g>
        </svg>
      </div>
      <div
        class="tw-max-w-[800px] tw-mx-auto tw-px-5 md:tw-px-10 lg:tw-px-20 tw-rounded-2xl md:tw-rounded-3xl tw-flex tw-flex-col tw-gap-5 lg:tw-gap-12 md:tw-text-xl tw-text-[#9d9da2] tw-text-center"
      >
        <p
          >{{ $t('kyc.fail_tip') }}</p
        >
        <i18n-t keypath="kyc.fail_tip2" tag="p">
          <a @click="onTouched" class="tw-text-[#c5ab80] tw-font-bold tw-cursor-pointer">{{$t('kyc.here')}}</a>
        </i18n-t>
      </div>
      <div class="tw-flex tw-justify-center">
        <UIButton @click="submit" class="tw-btn-lg md:tw-btn-xl xl:tw-btn-2xl"> {{ $t('kyc.back_to_application') }} </UIButton>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
  const route = useRoute()
  const router = useRouter()
  const submit = () => {
    router.push({
      ...route,
      query: {
        ...route.query,
        state: 0,
      },
    })
  }
  const zenDesk = useZenDesk()
  const onTouched = () => {
    zenDesk.onToggle()
  }
</script>
