<template>
  <KycCommonHeader :title="$t('kyc.onboarding')" @go-back="$router.back()" />
  <h1 class="tw-text-lg md:tw-text-xl tw-py-5 md:tw-py-3 tw-text-center"></h1>
  <div class="tw-py-7 tw-px-5 tw-text-center tw-card tw-bg-[#FAFAFB] tw-rounded-2xl md:tw-bg-transparent">
    <div class="tw-flex tw-flex-col tw-gap-10">
      <p class="tw-text-base md:tw-text-xl tw-font-bold">{{ $t('kyc.select_the_account_type') }}</p>
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-center tw-gap-5">
        <div>
          <button
            class="tw-btn tw-rounded-none tw-btn-xl tw-text-neutral tw-border-neutral tw-border-2 tw-px-12 hover:tw-bg-neutral/10"
            @click="kycType(0)"
            >{{ $t('kyc.individual') }}</button
          >
        </div>
        <div>
          <button
            class="tw-btn tw-rounded-none tw-btn-xl tw-text-neutral tw-border-neutral tw-border-2 tw-px-12 hover:tw-bg-neutral/10"
            @click="kycType(1)"
            >{{ $t('kyc.institution') }}</button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  const localeRoute = useLocaleRoute()
  const route = useRoute()
  const kycType = function (type: number) {
    const _route = localeRoute({ name: 'kyc', query: { ...route.query, state: `1${type}` } })
    if (_route) {
      return navigateTo(_route.fullPath)
    }
  }
</script>
