<template>
  <KycCommonHeader :title="$t('kyc.onboarding')" :subtitle="$t('kyc.individual')" @go-back="goBack" />
  <section class="tw-py-5 sm:tw-py-8 md:tw-py-10 lg:tw-py-14 tw-flex tw-flex-col tw-gap-5 sm:tw-gap-8 md:tw-gap-10 lg:tw-gap-12">
    <div role="tablist" class="tw-tabs tw-tabs-bordered tw-tabs-lg">
      <KycCommonStep
        class="arrow"
        :num="1"
        :currentIdx="current.index"
        :active="isCurrent('PersonalInfo')"
        :text="$t('kyc.personal_info')"
        :goto="goPage('PersonalInfo')"
      />
      <KycCommonStep
        class="arrow"
        :num="2"
        :currentIdx="current.index"
        :active="isCurrent('BankingInfo')"
        :text="$t('kyc.banking_info')"
        :goto="goPage('BankingInfo')"
      />
      <KycCommonStep
        class="arrow"
        :num="3"
        :currentIdx="current.index"
        :active="isCurrent('Questionnaire')"
        :text="$t('kyc.questionnaire')"
        :goto="goPage('Questionnaire')"
      />
      <KycCommonStep
        :num="4"
        :currentIdx="current.index"
        :active="isCurrent('Identification')"
        :text="$t('kyc.identification')"
        :goto="goPage('Identification')"
      />
    </div>
    <section class="tw-flex tw-flex-col tw-gap-4">
      <div class="tw-card tw-bg-secondary tw-rounded-2xl tw-py-14 tw-px-5 sm:tw-px-10 md:tw-px-20 lg:tw-px-20 tw-min-h-[400px]">
        <div class="tw-relative">
          <LazyKycIndividualPersonalInfo v-if="isCurrent('PersonalInfo')" :goToNext="goToNext">
            <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
          </LazyKycIndividualPersonalInfo>
          <LazyKycIndividualBankingInfo v-else-if="isCurrent('BankingInfo')" :goToNext="goToNext">
            <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
          </LazyKycIndividualBankingInfo>
          <LazyKycIndividualQuestionnaire v-else-if="isCurrent('Questionnaire')" :goToNext="goToNext">
            <KycCommonGoBack @go-back="goBack" class="tw-absolute tw-top-5 tw-hidden lg:tw-flex" />
          </LazyKycIndividualQuestionnaire>
          <LazyKycIndividualIdentification v-else-if="isCurrent('Identification')" :goToPrevious="goToPrevious" />
        </div>
      </div>
      <i18n-t keypath="kyc.continue_message" tag="p" class="tw-text-sm md:tw-text-base tw-text-center">
        <template #here>
          <NuxtLinkLocale to="/kyc#continue" class="tw-text-primary">
            {{ $t('kyc.here') }}
          </NuxtLinkLocale>
        </template>
      </i18n-t>
    </section>
  </section>
</template>
<script setup lang="ts">
  const router = useRouter()
  const { goToNext, current, isCurrent, goTo, goToPrevious, steps } = useStepper({
    PersonalInfo: {
      title: 'Personal Info',
      index: 1,
    },
    BankingInfo: {
      title: 'Banking Info',
      index: 2,
    },
    Questionnaire: {
      title: 'Questionnaire',
      index: 3,
    },
    Identification: {
      title: 'Identification',
      index: 4,
    },
  })
  const goBack = () => {
    if (isCurrent('PersonalInfo')) {
      router.back()
    } else {
      goToPrevious()
    }
  }
  const {
    public: { enableDevTools },
  } = useRuntimeConfig()

  const goPage = (pageName: string) => (i: number) => {
    if (!!enableDevTools) {
      goTo(pageName as any)
      return
    }
    if (current.value.index > i) {
      goTo(pageName as any)
    }
  }
</script>
<style scoped lang="scss">
  .arrow {
    &::after {
      color: #ceced0;
      content: '>';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%) scale(1.3);
    }
  }
</style>
