<template>
  <KycCommonHeader :title="$t('kyc.onboarding')" :subtitle="$t('kyc.institution')" @go-back="$router.back()" />
  <section class="tw-pt-5 tw-flex tw-flex-col tw-gap-14 tw-justify-center">
    <div class="tw-max-w-[660px] tw-mx-auto tw-py-7 tw-px-5 tw-text-center tw-card tw-rounded-2xl tw-bg-[#FAFAFB] md:tw-bg-transparent">
      <h5 class="tw-text-lg !tw-leading-tight tw-font-bold tw-text-center tw-mb-5 sm:tw-mb-8 md:tw-mb-10 lg:tw-mb-12">{{
        $t('kyc.please_get_ready')
      }}</h5>
      <section class="tw-flex tw-flex-col tw-gap-5">
        <div class="tw-max-auto tw-flex tw-flex-col tw-gap-2 md:tw-gap-3 lg:tw-gap-4">
          <h6 class="tw-text-base md:tw-text-sm tw-font-bold">{{ $t('kyc.entity_information') }}</h6>
          <ul class="tw-flex tw-flex-col tw-gap-2 md:tw-gap-3 lg:tw-gap-4">
            <li class="tw-flex tw-justify-start tw-items-baseline" v-for="(item, index) in $tm('kyc.ready_list_institution[0]')" :key="index">
              <SvgoShadowbgShapeInterrupt class="tw-w-4 tw-h-4 tw-mr-3" />
              <p class="tw-flex-1 tw-text-sm tw-text-left">{{ $rt(item) }}</p>
            </li>
          </ul>
        </div>

        <div class="tw-max-auto tw-flex tw-flex-col tw-gap-2 md:tw-gap-3 lg:tw-gap-4">
          <h6 class="tw-text-base md:tw-text-sm tw-font-bold">{{ $t('kyc.beneficial_owner_information') }} </h6>
          <ul class="tw-flex tw-flex-col tw-gap-2 md:tw-gap-3 lg:tw-gap-4">
            <li class="tw-flex tw-justify-start tw-items-baseline" v-for="(item, index) in $tm('kyc.ready_list_institution[1]')" :key="index">
              <SvgoShadowbgShapeInterrupt class="tw-w-4 tw-h-4 tw-mr-3" />
              <p class="tw-flex-1 tw-text-sm tw-text-left">{{ $rt(item) }}</p>
            </li>
          </ul>
        </div>
      </section>
    </div>

    <div class="tw-flex tw-justify-center">
      <UIButton
        class="tw-btn-lg md:tw-btn-xl xl:tw-btn-2xl !tw-text-base !xl:tw-text-xl"
        @click="
          $router.push({
            ...$route,
            query: { ...$route.query, state: '21' },
          })
        "
        data-institution-list-btn
      >
        {{ $t('kyc.ready_to_start') }}
      </UIButton>
    </div>
  </section>
</template>
