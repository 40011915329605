<template>
  <div class="tw-flex-1 tw-flex tw-flex-col tw-container tw-mx-auto tw-mt-5 md:tw-mt-10 lg:tw-mt-20 tw-mb-14 md:tw-mb-20 lg:tw-mb-24">
    <div class="tw-min-h-[400px] tw-flex tw-flex-col tw-items-center tw-justify-center" v-if="!isRecaptchaPassed">
      <GoogleRecaptcha
        class="tw-flex tw-justify-center"
        @success="onRecaptchaSuccess"
        @error="onRecaptchaError"
        @expired="onRecaptchaExpired"
      />
    </div>
    <section class="tw-flex-1 tw-flex tw-flex-col" v-else="isRecaptchaPassed">
      <KycWelcomeEnter v-if="state === 0" />
      <KycChooseType v-else-if="state === 1" />
      <KycAuthPage v-else />
    </section>
  </div>
</template>
<script setup lang="ts">
  const route = useRoute()
  const localeRoute = useLocaleRoute()
  const state = computed(() => parseInt((route.query.state as string) ?? '0'))

  const isRecaptchaPassed = useRecaptchaPassed()

  const onRecaptchaSuccess = async (response: string) => {
    try {
      const url = useRequestURL()
      const id = url.searchParams.get('id')
      const body = id ? { response, id } : { response }
      const data: { success: boolean; 'error-codes'?: string[]; sign?: string } = await $fetch('/api/google-recaptcha/verify', {
        method: 'POST',
        body,
      })
      if (data.success) {
        isRecaptchaPassed.value = true
        if (data.sign) {
          // update signature to meta after recaptcha succeeded
          const $meta = document.querySelector('meta[name=sign]')
          $meta?.setAttribute('content', data.sign)
          // debouncedWatchHandler(kycInfo.value)
          // getSign()
          const _route = localeRoute({ query: { ...route.query, state: route.query.state } })
          if (_route) {
            return navigateTo(_route.fullPath)
          }
        }
      } else {
        throw new Error(data['error-codes']?.join(','))
      }
    } catch (err) {
      displayErrors(err)
      const nuxtApp = useNuxtApp()
      nuxtApp.$recaptcha.reset()
    }
  }
  const onRecaptchaError = (error: Error) => {
    console.error(error)
  }
  const onRecaptchaExpired = () => {
    // console.log('expired')
  }

</script>
