<template>
  <Kyc v-if="success" />
</template>
<script setup lang="ts">
  const route = useRoute()
  const localeRoute = useLocaleRoute()

  const success = ref(false)
  onMounted(async () => {
    const data: { success: boolean; sign: string } = await $fetch('/api/google-recaptcha/sign', {
      body: { id: route.query.id },
      method: 'POST',
    })
    if (data.sign) {
      success.value = true
      const $meta = document.querySelector('meta[name=sign]')
      $meta?.setAttribute('content', data.sign)
      const _route = localeRoute({ query: { ...route.query, state: route.query.state } })
      if (_route) {
        return navigateTo(_route.fullPath)
      }
    }
  })
</script>
