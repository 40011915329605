<template>
  <div
    :data-sitekey="siteKey || nuxtApp.$recaptcha.siteKey"
    :data-size="computedDataSize"
    :data-theme="dataTheme"
    :data-badge="dataBadge"
    :data-tabindex="dataTabindex"
    data-callback="recaptchaSuccessCallback"
    data-expired-callback="recaptchaExpiredCallback"
    data-error-callback="recaptchaErrorCallback"
    class="g-recaptcha"
  />
</template>

<script setup lang="ts">
  import { LANGUAGES } from '~/config'

  const nuxtApp = useNuxtApp()

  const props = defineProps({
    siteKey: {
      type: String,
      default: '',
    },

    dataTheme: {
      default: 'light',
      type: String,

      validator: (value: string) => {
        return ['dark', 'light'].includes(value)
      },
    },

    dataSize: {
      default: null,
      type: String,

      validator: (value: string) => {
        return ['compact', 'normal', 'invisible'].includes(value)
      },
    },

    dataBadge: {
      default: 'bottomright',
      type: String,

      validator: (value: string) => {
        return ['bottomright', 'bottomleft', 'inline'].includes(value)
      },
    },

    dataTabindex: {
      default: 0,
      type: Number,
    },
  })

  const emit = defineEmits(['error', 'success', 'expired'])

  const computedDataSize = computed(() => {
    return props.dataSize || nuxtApp.$recaptcha.size || 'normal'
  })

  const onError = function (message: string) {
    return emit('error', message)
  }

  const onSuccess = function (token: string) {
    return emit('success', token)
  }

  const onExpired = function () {
    return emit('expired')
  }

  const { locale } = useI18n()
  const currenLang = computed(() =>
    LANGUAGES[locale.value as keyof ILanguages] ? LANGUAGES[locale.value as keyof ILanguages] : LANGUAGES['en'],
  )

  onMounted(() => {
    nuxtApp.$recaptcha.setLang(currenLang.value.recaptchaLanguageCode)
    nuxtApp.$recaptcha.init()

    nuxtApp.$recaptcha.on('recaptcha-error', onError)
    nuxtApp.$recaptcha.on('recaptcha-success', onSuccess)
    nuxtApp.$recaptcha.on('recaptcha-expired', onExpired)
  })

  onBeforeUnmount(() => {
    nuxtApp.$recaptcha.destroy()
  })

  watchEffect(() => {
    const lang = nuxtApp.$recaptcha.getLang()
    if (lang && lang !== currenLang.value.recaptchaLanguageCode) {
      setTimeout(() => {
        window.location.reload()
      }, 400)
    }
  })
</script>
